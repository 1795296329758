<template>
  <v-col>
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-form ref="form" v-model="valid">
            <v-card-title class="pa-8 pb-2">Topik</v-card-title>
            <!-- <div class="d-flex pl-8" style="gap: 10px; font-size: 13px;">
              <div class="d-flex" style="gap: 4px;">
                <div>Durasi Topik : </div>
                <div class="font-weight-medium" :class="duration ? 'primary--text': 'grey--text'">{{ duration ? 'Aktif' : 'Tidak Aktif' }}</div>
              </div>
              /
              <div class="d-flex" style="gap: 4px;">
                <div>Verifikasi Wajah : </div>
                <div class="font-weight-medium" :class="use_liveness ? 'primary--text': 'grey--text'">{{ use_liveness ? 'Aktif' : 'Tidak Aktif' }}</div>
              </div>
            </div> -->
            <div class="px-8 pb-4">
              <v-row>
                <v-col cols="12">
                  <div class="font-weight-medium mb-2">Judul Topik</div>
                  <v-text-field
                    outlined
                    placeholder="Masukkan judul topik"
                    v-model="title_topik"
                    required
                    :rules="[...rules('Judul Topik', 'required',), validate]"
                    :disabled="isDisabled"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="my-0 py-0" v-if="!isLoading && $route.name == 'activity-edit'">
                  <v-row>
                    <v-col cols="6" style="border-right: 1px solid grey;">
                      <div class="d-flex justify-space-between align-center">
                        <div>
                          <div class="d-flex justify-start font-weight-medium">
                            <div>Durasi Topik Selanjutnya</div>
                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-on="on"
                                  v-bind="attrs"
                                  small
                                  right>
                                  ri-question-line
                                </v-icon>
                              </template>
                              <div style="font-size: 12px;">
                                Durasi topik digunakan untuk mengatur rentang waktu user dapat mengakses topik selanjutnya. <br/>
                                Dihitung sejak penyelesaian aktivitas terakhir pada topik ini.
                                <!-- Rentang waktu user dapat mengakses topik berikutnya. <br/>
                                Durasi topik digunakan untuk mengatur topik selanjutnya. <br/>
                                Dihitung sejak penyelesaian aktivitas terakhir pada topik ini. -->
                              </div>
                            </v-tooltip>
                          </div>
                          <div class="grey--text" style="font-size: 11px;">Pengaturan durasi topik dalam format hari</div>
                          <div v-show="duration" class="primary--text" style="font-size: 12px;">Pengaturan durasi topik selanjutnya diatur selama <span class="font-weight-medium" style="font-size: 14px !important;">{{ delay_topic_duration }}</span> hari</div>
                        </div>
                        <v-chip :color="duration ? 'green' : 'red'" outlined>
                          {{ duration ? 'Digunakan' : 'Tidak digunakan' }}
                        </v-chip>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="d-flex justify-space-between align-center">
                        <div>
                          <div class="d-flex justify-start font-weight-medium">
                            <div>Verifikasi Wajah</div>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-on="on"
                                  v-bind="attrs"
                                  small
                                  right>
                                  ri-question-line
                                </v-icon>
                              </template>
                              <div style="font-size: 12px;">Verifikasi Wajah: Data wajah akan digunakan untuk mengonfirmasi keaslian identitas peserta</div>
                            </v-tooltip>
                          </div>
                          <div class="grey--text" style="font-size: 13px;">Verifikasi wajah untuk peserta kelas prakerja</div>
                        </div>
                        <v-chip :color="use_liveness ? 'green' : 'red'" outlined>
                          {{ use_liveness ? 'Digunakan' : 'Tidak digunakan' }}
                        </v-chip>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="my-0 py-0" v-if="!isLoading && $route.name != 'activity-edit'">
                  <v-row>
                    <v-col cols="10">
                      <div class="d-flex justify-start font-weight-medium">
                         <div>Durasi Topik Selanjutnya</div>
                         <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-on="on"
                              v-bind="attrs"
                              small
                              right>
                              ri-question-line
                            </v-icon>
                          </template>
                          <div style="font-size: 12px;">
                            Durasi topik digunakan untuk mengatur rentang waktu user dapat mengakses topik selanjutnya. <br/>
                            Dihitung sejak penyelesaian aktivitas terakhir pada topik ini.
                            <!-- Rentang waktu user dapat mengakses topik berikutnya. <br/>
                            Durasi topik digunakan untuk mengatur topik selanjutnya. <br/>
                            Dihitung sejak penyelesaian aktivitas terakhir pada topik ini. -->
                          </div>
                         </v-tooltip>
                        </div>
                      <div class="grey--text" style="font-size: 13px;">Pengaturan durasi topik dalam format hari</div>
                      <v-expand-transition>
                        <v-text-field 
                          outlined 
                          placeholder="Durasi dalam hari. Misal: 1"
                          type="number"
                          v-show="duration" 
                          v-model="delay_topic_duration"
                          :disabled="isDisabled"
                          class="my-2">
                        </v-text-field>
                      </v-expand-transition>
                      <div class="primary--text" style="font-size: 10px;">*Default durasi topik selanjutnya 1 hari setelah pengerjaan setiap topik</div>
                    </v-col>
                    <v-col>
                      <v-switch
                        v-model="duration"
                        inset
                        :disabled="isDisabled"
                        :label="duration ? 'Aktif' : 'Tidak Aktif'">
                      </v-switch>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="my-0 py-0" v-if="!isLoading && $route.name != 'activity-edit'">
                  <v-row>
                    <v-col cols="10">
                      <div class="d-flex justify-start font-weight-medium">
                         <div>Verifikasi Wajah</div>
                         <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-on="on"
                              v-bind="attrs"
                              small
                              right>
                              ri-question-line
                            </v-icon>
                          </template>
                          <div style="font-size: 12px;">Verifikasi Wajah: Data wajah akan digunakan untuk mengonfirmasi keaslian identitas peserta</div>
                         </v-tooltip>
                        </div>
                      <div class="grey--text" style="font-size: 13px;">Verifikasi wajah untuk peserta kelas prakerja</div>
                    </v-col>
                    <v-col>
                      <v-switch
                        v-model="use_liveness"
                        inset
                        :disabled="isDisabled"
                        :label="use_liveness ? 'Aktif' : 'Tidak Aktif'">
                      </v-switch>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <v-card-actions
              v-if="this.$route.name == 'activity-edit'"
              class="background"
            >
              <v-spacer></v-spacer>
              <!-- <v-btn color="primary" :loading="submitting" @click="save()">Simpan</v-btn> -->
            </v-card-actions>
            <v-card-actions v-else class="background">
              <v-spacer></v-spacer>
              <v-btn color="primary" :loading="submitting" @click="save()"
                >Simpan</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>

      <v-col v-if="isLoading" cols="12" class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
      <activity-form
        v-else
        ref="actForm"
        v-for="(activity, index) in activities"
        :seq="index + 1"
        :key="activity.id"
        :activity="activity"
        :submiting="submitting"
        @click:submit="submit"
        @update:activity="$set(activities, index, $event)"
        @click:cancel="
          $router.push({
            name: 'program-detail',
            params: { id: $route.params.program },
          })
        "
      ></activity-form>

      <v-snackbar
        top
        v-model="snackbar.state"
        :timeout="timeout"
        color="primary"
        outlined
      >
        <div v-html="snackbar.text"></div>
        <template v-slot:action="{ attrs }">
          <v-btn
            small
            icon
            color="error"
            v-bind="attrs"
            @click="snackbar.state = false"
          >
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </v-col>
</template>

<script>
import ActivityForm from "./ActivityForm";
import validationMixin from "../../_mixins/validation";

export default {
  mixins: [validationMixin],
  components: { ActivityForm },
  data: () => ({
    activities: [],
    aktifitas: false,
    simpan: true,
    title_topik: "",
    delay_topic_duration: 0,
    duration: false,
    use_liveness: false,
    submitting: false,
    valid: true,
    errors: null,
    isDisabled: false,
    snackbar: {
      state: false,
      text: "",
    },
    isLoading: null,
    timeout: 5000,
  }),
  watch: {
    duration: {
      handler(value) {
        // console.log(value);
        if(!value) return this.delay_topic_duration = 0
      },
      deep: true
    }
  },
  computed: {
    validate() {
      return !this.errors || this.errors.message;
    },
  },
  created() {
    if (this.$route.name == "activity-edit") {
      this.fetchACtivity();
    }
  },
  methods: {
    async submit(data) {
      if (data instanceof Error) {
        let message = data.message;
        if (data.response?.data?.message) {
          message = data.response.data.message;
        }
        this.snackbar.text = `Error: ${message}.`;
        this.snackbar.state = true;
        return;
      }

      this.submitting = true;
      const vm = this;

      try {
        const payload = {
          program: data.program.id,
          topic: data.topic.id,
          id: data.id,
          data: {
            type: data.type,
            title: data.title,
            settings: data.settings,
            theory: {
              ...data.theory,
              file:
                data.theory.file instanceof File ? data.theory.file : undefined,
            },
          },
          config: {
            onUploadProgress: (progress) => {
              vm.$refs.actForm[0].handleUpload(progress);
            },
          },
        };
        const program = this.$route.params.program;

        await this.$store.dispatch("activity/edit", payload);
        this.snackbar.text = `Aktivitas berhasil dibuat.`;
        this.snackbar.state = true;
        setTimeout(() => {
          this.$router.push({
            name: "program-detail",
            params: { id: program },
          });
          this.submiting = false;
        }, 1000);
      } catch (error) {
        let message = error.message;
        if (error.response?.data) {
          message = error.response.data.message;
        }
        if (error.response.data.validation) {
          const validation = error.response.data.validation;
          message = "";
          Object.keys(validation).forEach((key) => {
            message += `${key}: ${validation[key]}`;
          });
        }
        this.snackbar.text = message;
        this.snackbar.state = true;
        this.submitting = false;
        console.error(error);
      }
      this.submitting = false;
    },
    // async submitQuiz (datas) {
    //   const vm = this

    //   try {
    //     const payload = {
    //       program: this.$route.params.program,
    //       topic: this.$route.params.topic,
    //       id: this.$route.params.id,
    //       data: datas,
    //       config: {
    //         onUploadProgress: (progress) => {
    //           vm.$refs.actForm[0].handleUpload(progress);
    //         },
    //       },
    //     };
  
    //     await this.$store.dispatch("activity/edit_quiz", payload);
  
    //     this.snackbar.text = `Aktivitas berhasil diedit.`;
    //     this.snackbar.state = true;
    //     setTimeout(() => {
    //       this.$router.push({ name: "program-detail", params: { id: this.$route.params.program } });
    //       this.submiting = false;
    //     }, 1000);
    //   } catch (error) {
    //     console.error(error);
    //     this.snackbar.text = `Error: ${error.message}.`;
    //     this.snackbar.state = true;
    //   }

    // },
    async fetchACtivity() {
      this.isLoading = true;
      try {
        const { data: activity } = await this.$store.dispatch("activity/show", {
          program: this.$route.params.program,
          topic: this.$route.params.topic,
          id: this.$route.params.id,
        });
        if (this.$route.name == "activity-edit") {
          this.axios.get(`/topic/v1/detail/${this.$route.params.topic}`).then((response) => {
            let res = response.data
            this.title_topik = res.data.name;
            this.delay_topic_duration = res.data.setting.use_delay_next_topic;
            this.duration = res.data.setting.use_delay_next_topic ? true : false;
            this.use_liveness = res.data.setting.use_liveness;
            this.isDisabled = true;
          });
        }
        activity.status = "publish";
        if (activity.settings.is_show_discussion) {
          activity.settings.is_show_discussion = 1;
        } else {
          activity.settings.is_show_discussion = 0;
        }

        if (activity.settings.set_minimum_value.is_set) {
          activity.settings.set_minimum_value.is_set = 1;
        } else {
          activity.settings.set_minimum_value.is_set = 0;
        }

        if (activity.settings.is_skip) {
          activity.settings.is_skip = 1;
        } else {
          activity.settings.is_skip = 0;
        }

        if (activity.settings.is_show_timer) {
          activity.settings.is_show_timer = 1;
        } else {
          activity.settings.is_show_timer = 0;
        }

        if (activity.settings.is_show_score) {
          activity.settings.is_show_score = 1;
        } else {
          activity.settings.is_show_score = 0;
        }

        if(activity.settings.is_show_video_control){
          activity.settings.is_show_video_control = 1;
        }else{
          activity.settings.is_show_video_control=0;
        }

        if(!activity.theory.file_variants) {
          activity.theory.file_variants = []
        }
        
        this.activities.push(activity);
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
    onDragStart(e, index) {
      e.dataTransfer.setData("index", index);
    },
    onDrag(e) {
      if (!e.clientY) return;
      if (e.clientY < 100) {
        window.scrollTo({
          top: window.pageYOffset - 100,
          behavior: "smooth",
        });
      }
      if (e.clientY > window.outerHeight - 100) {
        window.scrollTo({
          top: window.pageYOffset + 100,
          behavior: "smooth",
        });
      }
    },
    onDrop(e, position) {
      const index = e.dataTransfer.getData("index");
      const activity = this.activities.splice(index, 1);
      this.activities.splice(position, 0, ...activity);
    },
    save() {
      this.errors = null;
      this.submiting = true;

      if(this.duration && Number(this.delay_topic_duration) <= 0) {
        this.snackbar.state = true;
        this.snackbar.text = "Durasi Topik harus lebih dari 0"
        return 
      }

      let topicName = this.title_topik
      if(topicName.trim().length <= 0) {
        this.snackbar.state = true;
        this.snackbar.text = "Nama Topik masih kosong"
        return 
      }

      setTimeout(() => {
        if (this.$refs.form.validate()) {
          let params = {
            name: this.title_topik,
            status: "publish",
            program_id: this.$route.params.id,
            delay_topic_duration: parseInt(this.delay_topic_duration),
            use_liveness: this.use_liveness
          };
          this.axios
            .post(`topic/v1/create`, params)
            .then((res) => res.data)
            .then(() => {
              // const data = res.data;
              this.submitting = false;
              this.snackbar.text = `Topik berhasil ditambahkan`;
              this.snackbar.state = true;
              this.aktifitas = true;
              this.simpan = false;
              this.isDisabled = true;
              this.$router.push({
                name: "program-detail",
                params: { program: this.$route.params.id },
              });
            })
            .catch((res) => {
              this.submitting = false;
              if (res.response) {
                this.snackbar.text = res.response.data.message;
                this.snackbar.state = true;
              }
            });
        }
      }, 50);
    },
  },
};
</script>
