<template>
  <v-row justify="center">
    
    <v-col v-if="!isPaketEmpty" cols="4" class="pt-5">
      <v-row class="flex-column" align-content="center">
        <v-col class="mx-auto">
          <v-img src="@/assets/images/data_empty.png" width="200" class="text-center mx-auto"/>
        </v-col>
        <v-col>
          <div class="title text-center">Tidak ada pesensi</div>
        </v-col>
      </v-row>
    </v-col>

    <v-col v-else>
      <v-row>
        <v-col id="search" lg="4" class="d-flex align-center">
          <v-autocomplete
            v-model="program"
            :items="program_list"
            :item-text="'title'"
            :item-value="'id'"
            dense
            filled
            clearable
            placeholder="Pilih nama program"
            @change="listTopik(program);fetchPeserta()"
            clear-icon="ri-close-fill"
          ></v-autocomplete>
        </v-col>
        <v-col id="search" lg="4" class="d-flex align-center">
          <v-autocomplete
            v-model="topic"
            :items="topic_list"
            :item-text="'name'"
            :item-value="'id'"
            dense
            filled
            placeholder="Pilih nama topik"
            @change="fetchPeserta()"
            clearable
            clear-icon="ri-close-fill"
          >
          </v-autocomplete>
        </v-col>
        <v-col lg="4" class="d-flex align-end justify-end">
          <v-btn
            color="primary"
            elevation="0"
            class="mr-2"
            @click="download()"
            :loading="submitting"
            >
              <v-icon class="mr-2">ri-download-line </v-icon> Export
            </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col id="dt_program">
          <presensi-list
            :loading="table_loading"
            :data="data"
            @sorting="sorting"
            @refresh-list="fetchPeserta"
            />
        </v-col>
      </v-row>
      <v-snackbar 
        top 
        v-model="snackbar.state"
        color="primary"
        outlined
        :timeout="timeout"
      >
        <div v-html="snackbar.text"></div>
        <template v-slot:action="{ attrs }">
          <v-btn
            small
            icon
            color="error"
            v-bind="attrs"
            @click="snackbar.state = false"
          >
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
import PresensiList from "./components/table";
import { mapGetters} from 'vuex'
// import moment from "moment";

export default {
  name: "user-list",
  components: { PresensiList},
  data: () => ({
    table_loading: false,
    loading:false,
    submitting:false,
    name:'',
    show:true,
    is_change: {
      show:false,
      id:''
    },
    snackbar: {
        state: false,
        text: "",
      },
    timeout:5000,
    reseller:{},
    seller:{},
    meta:{},
    idSeller:'',
    dir:'',

    dialog:false,
    url_:'',
    activePicker: null,
    date: null,
    menu: false,
    activePicker2: null,
    date2: null,
    menu2: false,
    list_reseller:[],
    list_referral:[],

    program_list:[],
    topic_list:[],
    program:'',
    topic:'',
  }),
  computed: {
    isPaketEmpty: () => true,
    // ...mapGetters("user", ["list", "page", "limit", "total"]),
    ...mapGetters({ data: "presensi/data", params: "presensi/params" }),
    // topics() {
    //   return this.topics || [];
    // },
  },
  mounted() {
    this.$emit("page-changed", 0, { title: "Presensi", link: `/presensi` });
  },
  created(){
    this.fetchPeserta();
    this.listProgram();
  },
  watch: {
  },
  methods: {
    listProgram() {
      this.axios
      .get("/program/v1/company/list?status=publish&limit=-1")
      .then((response) => {
        let res = response.data.data.list;
        this.program_list = res;
      });
    },
    listTopik(id) {
      if (id == undefined) {
        id = ''
        this.topic = ''
        this.topic_list = []
      } else {
        setTimeout(() => {
          this.axios
          .get(`/topic/v1/list?program_id=${id}&limit=-1`)
          .then((response) => {
            let res = response.data.data.list;
            this.topic_list = res;
          });
        })
      }
    },
    fetchPeserta() {
      if (this.program == undefined) {
        this.program = ''
      }
      if (this.topic == undefined) {
        this.topic = ''
      }
      this.table_loading = true;
      setTimeout(() => {
        this.$store.dispatch("presensi/list",{program_id:this.program,topic_id:this.topic}).finally(() => {
          this.table_loading = false;
        });
      }, 200)
    },
    sorting(value) {
      if (value === true) {
        this.dir='desc'
      }else{this.dir='asc'}
      this.table_loading = true;
      this.$store.dispatch("presensi/list",{program_id:this.program,topic_id:this.topic,sort:'created_at',dir:this.dir}).finally(() => {
        this.table_loading = false;
      });
    },
    download() {
      this.submitting=true;
      this.axios
      .get(`presence/v1/company/export?limit=-1&page=1&program_id=${this.program}&topic_id=${this.topic}`)
      .then((res) =>{
        this.submitting=false;
        this.url_ = res.data.data.public_url; 
        this.forceFileDownload(this.url_)
      })
      .catch(() => {
        this.submitting=false;
      });
    },
    forceFileDownload(url) {
      // const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'file.png') //or any other extension
      document.body.appendChild(link)
      link.click()
    },     
  },
};
</script>