<template>
  <v-row>
    <v-col v-for="(question, index) in questions" :key="index" cols="12">
      <div class="rounded pa-4" style="border: 1px solid #9db0c6;">
        <div class="d-flex justify-space-between align-center">
          <h3>Nomor {{ index + 1 }}</h3>
          <v-btn icon @click.stop="deleteQuestion(question, index)">
            <v-icon> $delete </v-icon>
          </v-btn>
        </div>

        <v-row>
          <v-col cols="12" v-for="(option, indx) in question.options" :key="`${index}${indx}`" style="">
            <v-row class="ma-0" align="end" style="border: solid 1px #9db0c6">
              <v-col cols="12">
                <div class="d-flex justify-space-between align-center">
                  <h3>Opsi {{ indx + 1 }}</h3>
                  <v-btn icon @click.stop="deleteOption(index, indx)">
                    <v-icon> $delete </v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="6">
                <p class="mb-2">Sisi Kiri</p>
                <input-text-image v-model="question.options[indx]" />
                <div v-if="question.options[indx].error" class="red--text" style="font-size: 10px;">
                  {{ question.options[indx].error }}
                </div>
              </v-col>
              <v-col cols="6">
                <p class="mb-2">Sisi Kanan</p>
                <input-text-image v-model="question.correct_answers[indx]" />
                <div v-if="question.correct_answers[indx].error" class="red--text" style="font-size: 10px;">
                  {{ question.correct_answers[indx].error }}
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-btn text @click="addOption(index)">
              <v-icon left>$add</v-icon>
              Opsi Jawaban
            </v-btn>
          </v-col>

          <v-col cols="12">
            <p class="mb-2">Poin</p>
            <v-text-field class="mb-2" v-model="question.point"  placeholder="0" outlined filled color="primary" hide-details="auto" />
          </v-col>
          <!-- <v-col cols="6">
            <p class="mb-2">Timer (detik)</p>
            <v-text-field class="mb-2" v-model="question.time" outlined filled color="primary" hide-details="auto" />
          </v-col> -->
        </v-row>

        <v-alert v-show="question.errorData" type="error" text outlined>
          <div style="white-space: pre;">{{ question.errorData }}</div>
        </v-alert>


        <!-- <v-alert type="info" text outlined dense>
          <div style="font-size: 12px;">
            Jangan lupa klik <strong>Simpan Soal</strong> setelah selesai mengisi data dengan lengkap.
          </div>
        </v-alert> -->

        <div class="text-right  mt-3">
          <v-btn color="primary" type="button" :loading="isLoading" class="white--text text-right" @click="savedGameplays(question, index)">
            Simpan Soal
          </v-btn>
        </div>
      </div>
    </v-col>

    <v-col cols="12">
      <v-btn :disabled="questions.length != gameplays.length" color="primary" outlined @click="addQuestion">
        Tambah Soal
      </v-btn>
    </v-col>

    <v-dialog v-model="delete_question.show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span> <v-icon>$warning</v-icon> Hapus Soal </span>
        </v-card-title>
        <v-divider></v-divider>
        <div>
          <v-card-text>
            Apakah anda ingin menghapus soal ini?
          </v-card-text>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="delete_question.deleting" outlined color="primary" large @click="cancelDeleteQuestion()">
            Batal
          </v-btn>
          <v-btn
            :loading="delete_question.deleting"
            class="white--text"
            color="primary"
            large
            @click="confirmDeleteQuestion()"
          >
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
const blankQuestion = {
  id: "",
  question: null,
  type: "",
  errorData: "",
  point: null,
  time: null,
  options: [{ text: "",  image: "", audio: "", answer: "" }],
  matching_options: [{ text: "",  image: "", audio: "", answer: "" }],
  correct_answers: [{ text: "",  image: "", audio: "", answer: "" }],
};

import model from "../../_mixins/model";
import InputTextImage from "./InputTextImage.vue";
// import InputTextImageAnswer from "./InputTextImageAnswer.vue";

export default {
  components: { InputTextImage },
  mixins: [model],
  data: () => ({
    delete_question: { show: false, index: null, id: "" },
    snackbar: {
      state: false,
      text: "",
    },
    isSaveLastData: false,
    isLoading: null,
    timeout: 5000,
    questions: [],
    questionError: [],
    gameplays: []
  }),
  created() {
    this.fetchDetailGameplay();
  },
  methods: {
    async fetchDetailGameplay() {
      // const this.data.gameplays.toString()IDS = this.data.gameplays.toString()

      // console.log('IDS',IDS);

      
      const {data: detailQuiz} = await this.$store.dispatch("gameplay/show_multi", {
        ids: `${this.data.gameplays.toString()}`
      })

      for (let i = 0; i < detailQuiz.length; i++) {
        const detail = detailQuiz[i];
        let data = []
        let dataOptions = []
        for (let j = 0; j < detail.correct_answers.length; j++) {
          const element = detail.correct_answers[j]

          // console.log(element);
          if(element.includes('https')) {
            data.push({
              text: "",
              image: element,
              error: "",
              answer: element
            })
          }else {
            data.push({
              text: element,
              image: "",
              error: "",
              answer: element
            })
          }
        }

        for (let j = 0; j < detail.options.length; j++) {
          const element = detail.options[j]
            if(element.image) {
              dataOptions.push({
                text: "",
                image: element.image,
                error: "",
                answer: element.image
              })
            }else {
              dataOptions.push({
                text: element.text,
                image: "",
                error: "",
                answer: element.text
              })
            }
        }

        // console.log(dataOptions);

        detail.correct_answers = data
        detail.options = dataOptions
      }

      this.questions = detailQuiz


      this.gameplays = this.data.gameplays
    },
    addQuestion() {
      // this.questions.push({ options: [{ left: {}, right: {} }] });
      this.questions.push({ ...JSON.parse(JSON.stringify(blankQuestion)), type: "matching_word" });
    },
    addOption(index) {
      this.questions[index].options.push({ text: "",  image: "", audio: "", answer: "" });
      this.questions[index].correct_answers.push({ text: "",  image: "", audio: "", answer: "" });
    },
    deleteOption(i1, i2) {
      this.questions[i1].options.splice(i2, 1);
      this.questions[i1].correct_answers.splice(i2, 1);
    },
    deleteQuestion(data, index) {
      this.delete_question.show = true;
      this.delete_question.index = index;
      this.delete_question.id = data.id
      this.snackbar.text = ""
    },
    cancelDeleteQuestion() {
      this.delete_question.show = false;
      this.delete_question.index = null;
      this.delete_question.id = ""
      this.snackbar.text = ""
    },
    confirmDeleteQuestion() {
      this.questions.splice(this.delete_question.index, 1);

      // DELETE DATA GAMEPLAY ID
      let indexData = this.gameplays.findIndex((item) => this.delete_question.id  == item);
      this.gameplays.splice(indexData, 1)

      this.delete_question.show = false;
      this.delete_question.index = null;
      this.delete_question.id = ""
      this.snackbar.text = ""

      this.$emit('click:submit',{
        type: "matching_word",
        gamplays: this.gameplays,
        saving: false
      })
    },

    validate(key) {
      var isValid = true;
      // for (let i = 0; i < this.questions.length; i++) {
      // }

      const question = this.questions[key]; // or question
      question.errorData = ""

      if (!(question.options && question.options.length) && question.options.length < 1) {
        isValid = false
        question.errorData += "Opsi Kiri tidak boleh kosong\n"
      }

      if(question.options.length > 0){
        question.options.forEach((item, index) => {
          if(!item.answer) {
            question.errorData += `Sesi Kiri opsi ${index + 1} masih kosong\n`
            isValid = false
          }
        })
      }

      if (!(question.correct_answers && question.correct_answers.length) && question.correct_answers.length < 1) {
        isValid = false
        question.errorData += "Opsi Kanan tidak boleh kosong\n"
      }

      if(question.correct_answers.length > 0){
        question.correct_answers.forEach((item, index) => {
          if(!item.answer) {
            question.errorData += `Sesi Kanan opsi ${index + 1} masih kosong\n`
            isValid = false
          }
        })
      }

      if (!question.point || question.point == 0) {
        isValid = false
        question.errorData += "Poin tidak boleh kosong atau 0\n"
      }

      setTimeout(() => {
        question.errorData = ""
      }, 3000);
      
      return isValid;
    },

    async saveGameplay(data) {
      // const headers = { "content-type": "application/json" };
      if (data.id) {
        let payload = {
          id: data.id,
          data: data
        }
        return await this.$store.dispatch(`gameplay/edit`, payload);
      } else {
        return await this.$store.dispatch(`gameplay/create`, data);
      }
    },

    async savedGameplays(question, index) {

      this.isLoading = true

      let isValid = this.validate(index)
      
      let answer = []
      question.correct_answers.forEach((item) => {
        answer.push(item.answer)
      });

      
      let dataQuestion = {
        id: question.id,
        type: "matching_word",
        question: null,
        point: Number(question.point),
        time:  Number(question.time),
        options: question.options,
        matching_options:  question.options,
        correct_answers: answer,
      }
      
      if (isValid) {
        try {
          await this.saveGameplay(dataQuestion)
            .then((res) => {
              // console.log(res);
              let response = res.data
  
              this.questions[index].id = response.id
  
              let gametrue = this.gameplays.includes(response.id)
  
              if(!gametrue){
                this.gameplays.push(response.id);
              }
  
              // console.log('gameplays',this.gameplays);
              this.$emit('click:submit',{
                type: "matching_word",
                gameplays: this.gameplays,
                saving: true
              })
  
              this.isLoading = false;
            }).catch(async (error) => {
              this.isLoading = false;
              this.snackbar.text = error.message
              throw error;
              // reject(error);
            });
        } catch (error) {
          this.isLoading = false;
          throw error;
        }
      }else {
        this.isLoading = false
      }

    },
  },
};
</script>
