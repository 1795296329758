var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[(_vm.loading)?[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-skeleton-loader',{attrs:{"type":"paragraph"}})],1)]:[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('p',{staticClass:"mb-2"},[_vm._v("Tipe Soal")]),_c('v-select',{attrs:{"filled":"","outlined":"","items":_vm.types,"hide-details":"auto"},model:{value:(_vm.data.type),callback:function ($$v) {_vm.$set(_vm.data, "type", $$v)},expression:"data.type"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('p',{staticClass:"mb-2"},[_vm._v("Pertanyaan")]),_c('vue-editor',{attrs:{"id":"editor1","disabled":_vm.isDisabled,"placeholder":_vm.isDisabled ? '' : 'Masukan pertanyaan',"editor-toolbar":_vm.customToolbar,"useCustomImageHandler":""},on:{"image-added":_vm.handleImageAdded},model:{value:(_vm.question.text),callback:function ($$v) {_vm.$set(_vm.question, "text", $$v)},expression:"question.text"}})],1),(this.$route.name === 'activity-edit')?_c('v-col',{attrs:{"cols":"12","md":"12"}},[(_vm.question.media !='')?_c('div',[_c('p',{staticClass:"mb-2"},[_vm._v("Attachment Audio")]),(_vm.isMediaSet('audio'))?_c('audio-attachment',{staticClass:"d-inline-block",attrs:{"disabled":_vm.isDisabled,"src":_vm.question.media[0]._id},on:{"update":function($event){return _vm.$set(_vm.question, 'media',[
                ...$event.id,
              ])}}}):_vm._e(),(_vm.isDisabled && _vm._.isEmpty(_vm.question.media))?_c('span',[_vm._v(" "+_vm._s("No media")+" ")]):_vm._e()],1):_c('div',[_c('p',{staticClass:"mb-2"},[_vm._v("Attachment Audio")]),(_vm.isMediaSet('audio'))?_c('audio-attachment',{staticClass:"d-inline-block",attrs:{"disabled":_vm.isDisabled,"src":_vm.question.media},on:{"update":function($event){return _vm.$set(_vm.question, 'media',[
                ...$event.id,
              ])}}}):_vm._e(),(_vm.isDisabled && _vm._.isEmpty(_vm.question.media))?_c('span',[_vm._v(" "+_vm._s("No media")+" ")]):_vm._e()],1)]):_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('p',{staticClass:"mb-2"},[_vm._v("Attachment Audio")]),(_vm.isMediaSet('audio'))?_c('audio-attachment',{staticClass:"d-inline-block",attrs:{"disabled":_vm.isDisabled,"src":_vm.question.media},on:{"update":function($event){return _vm.$set(_vm.question, 'media',[
              ...$event.id,
            ])}}}):_vm._e(),(_vm.isDisabled && _vm._.isEmpty(_vm.question.media))?_c('span',[_vm._v(" "+_vm._s("No media")+" ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"row"},[_c('p',{staticClass:"col-md-6 mb-2"},[_vm._v("Opsi Jawaban")]),_c('p',{staticClass:"col-md-6 mb-2",staticStyle:{"text-align":"right"}},[_vm._v("Jawaban yang benar")])]),_c('question-options',{attrs:{"disabled":_vm.isDisabled},model:{value:(_vm.options),callback:function ($$v) {_vm.options=$$v},expression:"options"}})],1),(_vm.data.type ==='multiple_choice')?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('p',{staticClass:"mb-2"},[_vm._v("Poin")]),_c('v-text-field',{staticClass:"mb-2",attrs:{"disabled":_vm.isDisabled,"outlined":"","filled":"","color":"primary","hide-details":"auto","placeholder":"0"},model:{value:(_vm.data.point),callback:function ($$v) {_vm.$set(_vm.data, "point", _vm._n($$v))},expression:"data.point"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('p',{staticClass:"mb-2"},[_vm._v("Pembahasan")]),_c('vue-editor',{attrs:{"id":"editor2","placeholder":_vm.isDisabled ? '' : 'Masukan pembahasan',"disabled":_vm.isDisabled,"editor-toolbar":_vm.customToolbar,"useCustomImageHandler":""},on:{"image-added":_vm.handleImageAddedExplan},model:{value:(_vm.explanation.correct.text),callback:function ($$v) {_vm.$set(_vm.explanation.correct, "text", $$v)},expression:"explanation.correct.text"}})],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }