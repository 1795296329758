<template>
  <div>
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      :items="data.list"
      :items-per-page.sync="data.limit"
      :page.sync="data.page"
      :item-key="'id'"
      class="transparent"
      :mobile-breakpoint="0"
      :server-items-length="data.total_data"
      loading-text="Loading... Please wait"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :footer-props="{
        'items-per-page-text':'Row per page : '
      }"
    >
      <template v-slot:header.progress="{ header }">
        <th v-if="sortDesc===false" @click="sortCalories" style="cursor: pointer;">{{ header.text }} <v-icon small>ri-arrow-up-line</v-icon></th>
        <th v-else @click="sortCalories" style="cursor: pointer;">{{ header.text }} <v-icon small>ri-arrow-down-line</v-icon></th>
      </template>
      <template slot="item" slot-scope="{ item }">
        <tr class="flat-b-1 rounded-lg">
          <td class="white text-capitalize rounded-l-lg" :style="parseDataJSON(item).bgColor" v-text="item.Reference.UniqueID"></td>
          <td class="white text-capitalize" :style="parseDataJSON(item).bgColor"> {{ parseData(item.Request.Body) }}</td>
          <td class="white text-capitalize" :style="parseDataJSON(item).bgColor" v-text="item.Member.fullname" style="width: 25%;"></td>
          <td class="white text-capitalize" :style="parseDataJSON(item).bgColor" v-text="item.Member.phone"></td>
          <td class="white text-capitalize" :style="parseDataJSON(item).bgColor">{{ convertCreatedAt(item.CreatedAt) }}</td>
          <!-- <td class="white text-capitalize">{{ item.results.progress }}%</td> -->
          <!-- <td class="white text-capitalize" v-text="item.pre"></td>
          <td class="white text-capitalize" v-text="item.post"></td> -->
  
          <!-- <td class="white text-capitalize">
            <v-chip
              v-if="item.status == 'archive'"
              :ripple="false"
              class="pa-1 text-capitalize"
              color="primary"
              outlined
              label
              small
              v-text="item.status"
            ></v-chip>
            <v-chip
              v-else
              :ripple="false"
              class="pa-1 text-capitalize"
              :color="item.status == 'draft' ? 'default' : 'success'"
              outlined
              label
              small
              v-text="item.status"
            ></v-chip>
          </td> -->
          <td class="white rounded-r-lg" :style="parseDataJSON(item).bgColor" style="width: 5%">
            <div class="d-flex flex-row">
              <v-menu offset-y>
                <template slot="activator" slot-scope="{ on, attrs }">
                  <v-btn title="more" icon v-bind="attrs" v-on="on">
                    <v-icon :color="parseDataJSON(item).iconColor">ri-more-line</v-icon>
                  </v-btn>
                </template>
  
                <v-list>
                  <v-list-item v-if="parseDataJSON(item).contentValue" link @click="copyToClipboard(parseDataJSON(item).contentValue)">
                    <v-list-item-title>Copy Link State</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="$emit('detail', item)">
                    <v-list-item-title>Detail Prakerja</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </td>
        </tr>
      </template>    
    </v-data-table>

    <v-snackbar 
      top 
      v-model="snackbar.state"
      color="primary"
      outlined
      :timeout="snackbar.timeout">
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="error"
          v-bind="attrs"
          @click="snackbar.state = false"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "program-table",
  props: {
    // data: { required: true, type: Object, default: () => ({}) },
    data: { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: () => false },
  },
  data: () => ({
    sortBy: "progress",
    sortDesc: true,
    headers: [
      { text: "Kode Voucher", class: "text-no-wrap" ,value:"Reference.UniqueID", sortable: false, },
      { text: "Kode Redeem", class: "text-no-wrap" ,value:"pmo_code", sortable: false, },
      { text: "Nama Peserta", class: "text-no-wrap" ,value:"name",sortable: false },
      { text: "Nomor Whatsapp", class: "text-no-wrap" ,value:"phone",sortable: false },
      { text: "Waktu Redeem", class: "text-no-wrap",value:"CreateAt", sortable: false},
      { text: "Aksi", class: "text-no-wrap",sortable: false },
    ],
    item:[
      {
        kode_voucher:'16AE41332TY90',
        nama:'Medina Bella E',
        no_wa:'+62 87848903467',
        status:'90%',
        // pre:80,
        // post:92
      },      
    ],
    snackbar: {
      state: false,
      text: "",
      timeout: 6000,
    },
    table_loading: false,
  }),
  computed: {
    convertCreatedAt() {
      return (date) => {
        return moment(date).locale('id').format("DD MMMM YYYY HH:mm:ss");
      };
    },
    isLoading() {
      return this.loading || this.table_loading;
    },
  },
  methods: {
    parseData(items) {
      let data = JSON.parse(items);
      return data.redeem_code;
    },
    parseDataJSON(item) {
      let bgColor = '';
      let iconColor = '';
      let contentValue = '';
      item.Response.map((data) => {
        if(data.Key == "data") {
          if(data.Value != null) {
            data.Value.map((value) => {
              if (value.Key == 'state' && value.Value !== '') {
                // F05326
                // FB6D48
                // d35400
                bgColor = 'background-color: #324577 !important; color: white;';
                iconColor = 'white';
                contentValue = value.Value;
              }
            });
          }
        }
      });

      return {bgColor, iconColor, contentValue};
    },
    copyToClipboard(text) {
      const el = document.createElement("textarea");
      //check domain name
      if (window.location.hostname === "localhost" || window.location.hostname === "lp-profesi.cms-dev.solutionlab.id/") {
        el.value = `https://lp-profesi.lms-dev.solutionlab.id/prakerja/callback?state=${text}`;
      }else {
        el.value = `https://edukasi.profesimu.co.id/prakerja/callback?state=${text}`;
      }
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.snackbar.text = "State copied to clipboard";
      this.snackbar.state = true;
    },
    paginate: (current, total) => {
      if (current > total) {
        throw new Error("Current page is larger than total");
      }
      let pages = [];
      if (total > 5) {
        pages = new Array(5);
        pages = pages.fill(0).map((val, i) => {
          if (current > 3 && current < total - 2) {
            switch (i) {
              case 0:
                return current > 3 ? "..." : i + current - 2;
              case 4:
                return current < total - 2 ? "..." : i + current - 2;
              default:
                return i + current - 2;
            }
          }

          if (current <= 3) {
            return i == 4 ? "..." : i + 1;
          }

          if (current >= total - 2) {
            return i == 0 ? "..." : i + total - 4;
          }
        });
      } else {
        pages = new Array(total);
        pages = pages.fill(0).map((val, i) => i + 1);
      }

      return pages;
    },
    sortCalories() {
      if (this.sortDesc === false) {
        this.sortDesc = true;
      }else{
        this.sortDesc = false;        
      }
      // console.log('sort',this.sortDesc)
      this.$emit("sorting",this.sortDesc);
    }
  },
  watch: {
    "data.limit": function () {
      this.$emit("refresh-list");
    },
    "data.page": function () {
      this.$emit("refresh-list");
    },
  }
};
</script>