export default {
  admin_login: '/users/v1/company/login',
  admin_detail: '/users/v1/company/detail',

  // program
  program_list: '/program/v1/company/list',
  program_create: '/program/v1/company/create',
  program_detail: '/program/v1/company/detail/{id}',
  program_update: '/program/v1/company/update/{id}',

  //media
  media_upload: '/storage/v1/media/company/upload',

  // storage
  media_list: '/storage/v1/media/company/list',
  media_detail: '/storage/v1/media/company/detail/{id}',
  media_detail_public: '/storage/v1/media/company/public/{id}',
  // media_upload: '/storage/v1/media/company/upload',

  //studi
  studi_list: '/study/v1/list',
  studi_create: '/study/v1/create',
  studi_detail: '/study/v1/detail/{id}',
  studi_update: '/study/v1/update/{id}',

  //user
  user_list: '/program/v1/company/list-member-redeem/{id}',
  user_detail: '/study/v1/detail/{id}',

  //notif
  notif_list: '/notification/v1/list',

  //reseller
  reseller_list:'/reseller/v1/list',
  reseller_list_user:'/reseller/v1/list-user-reseller',

  //presensi
  presence_list:'/presence/v1/company/list',

  // Activity
  activity_list: (payload) => `/v1/activity/${payload.program}/${payload.topic}/list`,
  activity_create: (payload) => `/v1/activity/${payload.program}/${payload.topic}/create`,
  activity_show: (payload) => `/v1/activity/${payload.program}/${payload.topic}/${payload.id}/show`,
  activity_update: (payload) => `/v1/activity/${payload.program}/${payload.topic}/${payload.id}/update`,
  activity_update_quiz: (payload) => `/v1/activity/${payload.program}/${payload.topic}/${payload.id}/update-quiz`,
  activity_reorder: (payload) => `/v1/activity/${payload.program}/${payload.topic}/reorder`,
  activity_approve: (payload) => `/v1/activity/${payload.program}/${payload.topic}/${payload.id}/approve`,
  activity_delete: (payload) => `/v1/activity/${payload.program}/${payload.topic}/${payload.id}/delete`,

  // Activity
  gameplay_list: `/gameplay/v1/list`,
  gameplay_create: `/gameplay/v1/create`,
  gameplay_show: (payload) => `/gameplay/v1/detail/${payload.id}`,
  gameplay_show_multi: `/gameplay/v1/detail-multiple`,
  gameplay_update: (payload) => `/gameplay/v1/update/${payload}`,
  gameplay_delete: (payload) => `/gameplay/v1/delete/${payload.id}`,

  // Trainer
  trainer_list: `/trainer/v1/company/list`,
  trainer_program_list: `/program/v1/trainer/list`,
  trainer_create: `/trainer/v1/company/create`,
  trainer_show: (payload) => `/trainer/v1/company/detail/${payload.id}`,
  trainer_update: (payload) => `/trainer/v1/company/update/${payload}`,
  trainer_delete: (payload) => `/trainer/v1/company/delete/${payload}`,

  //section
  section_list: (payload) => `/v1/section/${payload.activity}/list`,
  section_create: (payload) => `/v1/section/${payload.activity}/create`,
  section_show: (payload) => `/v1/section/${payload.activity}/${payload.id}/show`,
  section_update: (payload) => `/v1/section/${payload.activity}/${payload.id}/update`,
  section_approve: (payload) => `/v1/section/${payload.activity}/${payload.id}/approve`,
  section_delete: (payload) => `/v1/section/${payload.activity}/${payload.id}/delete`,
  
  // packet
  packet_list: (payload) => `/v1/packet/${payload.program}/${payload.topic}/${payload.activity}/list`,
  packet_create: (payload) => `/v1/packet/${payload.program}/${payload.topic}/${payload.activity}/create`,
  packet_show: (payload) => `/v1/packet/${payload.program}/${payload.topic}/${payload.activity}/${payload.id}/show`,
  packet_update: (payload) => `/v1/packet/${payload.program}/${payload.topic}/${payload.activity}/${payload.id}/update`,
  packet_approve: (payload) => `/v1/packet/${payload.program}/${payload.topic}/${payload.activity}/${payload.id}/approve`,
  packet_delete: (payload) => `/v1/packet/${payload.program}/${payload.topic}/${payload.activity}/${payload.id}/delete`,
  
  // group
  group_list: (payload) => `/v1/group/${payload.program}/${payload.topic}/${payload.activity}/${payload.packet}/list`,
  group_create: (payload) => `/v1/group/${payload.program}/${payload.topic}/${payload.activity}/${payload.packet}/create`,
  group_show: (payload) => `/v1/group/${payload.program}/${payload.topic}/${payload.activity}/${payload.packet}/${payload.id}/show`,
  group_update: (payload) => `/v1/group/${payload.program}/${payload.topic}/${payload.activity}/${payload.packet}/${payload.id}/update`,
  group_approve: (payload) => `/v1/group/${payload.program}/${payload.topic}/${payload.activity}/${payload.packet}/${payload.id}/approve`,
  group_delete: (payload) => `/v1/group/${payload.program}/${payload.topic}/${payload.activity}/${payload.packet}/${payload.id}/delete`,
}