<template>
  <v-row justify="center">
    <v-col v-if="isPaketEmpty" cols="4">
      <v-row class="flex-column" align-content="center">
        <v-col>
          <v-img src="@/assets/images/data_empty.png" />
        </v-col>
        <v-col>
          <div class="title text-center">Tidak ada program</div>
        </v-col>
        <div class="subtitle body-1 text-center">
          klik tombol di bawah ini untuk menambahkan program baru
        </div>
        <v-col class="text-center">
          <v-btn color="primary" elevation="0">
            <v-icon>ri-add-line</v-icon> Program baru
          </v-btn>
        </v-col>
      </v-row>
    </v-col>

    <v-col v-else>

      <v-row>
        <v-col class="my-0 py-0">
          <v-radio-group
            v-model="class_type"
            row>
            <v-radio
              label="Semua Program"
              :value="0">
            </v-radio>
            <v-radio
              label="Webinar"
              :value="1">
            </v-radio>
            <v-radio
              label="Pembelajaran Mandiri"
              :value="2">
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col lg="7">
          <v-tabs
            v-model="tabPaket"
            class="badged-tabs"
            color="primary"
            show-arrows="mobile"
          >
            <v-tab class="text-capitalize">
              <v-badge :content="data.total" inline>Semua Program</v-badge>
            </v-tab>
            <v-tab class="text-capitalize">
              <v-badge :content="dataPublish.total.toString()" inline> Published </v-badge>
            </v-tab>
            <v-tab class="text-capitalize">
              <v-badge :content="dataDraft.total.toString()" inline> Draft </v-badge>
            </v-tab>
            <v-tab class="text-capitalize">
              <v-badge :content="dataArchive.total.toString()" inline> Archive </v-badge>
              <!-- <v-badge :content="totalArchive" inline> Archive </v-badge> -->
            </v-tab>
          </v-tabs>
        </v-col>

        <v-col id="search" lg="5" class="d-flex justify-end align-center">
          <v-menu offset-y>
            <v-list>
              <v-list-item v-for="(item, index) in ['Nama Program']" :key="index">
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- <v-text-field
            label="Regular"
            single-line
            append-icon="$search"
          ></v-text-field> -->
          <v-text-field
            append-icon="$search"
            class="pt-0 mt-0 mr-4"
            placeholder="Search"
            v-model="name_program"
            @keydown.enter="fetchAll"
            outlined
          ></v-text-field>
          <v-menu
            bottom
            offset-y
            origin="center center"
            transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="mr-3"
                dark
                small
                v-bind="attrs"
                v-on="on">
                <v-icon class="mr-2">ri-download-line </v-icon> Export
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="dialog=true;dialogState='program';listProgram()">
                <v-list-item-content>
                  <v-list-item-title>
                    Export Program
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="dialog=true;dialogState='epm';listProgram()">
                <v-list-item-content>
                  <v-list-item-title>
                    Export TPM & UK
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- <v-btn
            small
            color="teal"
            elevation="0"
            class="mr-2 white--text"
            v-if="$can('export', 'Program')"      
            @click="dialog=true;listProgram()"
          >
            <v-icon class="mr-2">ri-download-line </v-icon> Export EPM
          </v-btn>
          <v-btn
            small
            color="primary"
            elevation="0"
            class="mr-2"   
            @click="dialog=true;listProgram()"
          >
            <v-icon class="mr-2">ri-download-line </v-icon> Export
          </v-btn> -->
          <v-btn
          v-if="$can('create', 'Program')"      
            small
            color="primary"
            elevation="0"
            :to="{
              name: 'add-program'
            }"
          >
            <v-icon>ri-add-line</v-icon> Program baru 
          </v-btn>
        </v-col>
        <!-- <download-excel 
          :data="json_data"
          :fields="json_fields"
          name="Data-Export.xls"
        >
          Download Data
        </download-excel> -->
      </v-row>

      <v-row>
        <v-col>
          <v-tabs-items id="dt_program"  v-model="tabPaket" class="transparent">
            <v-tab-item>
              <program-all
                :loading="table_loading"
                :data="data"
                @refresh-list="fetchProgram"
                @remove="remove"
                @update="update"
                @sorting="sorting"
              ></program-all>
              <!-- <program-all
                @remove="remove"
                @update="update"
                :data="{ perPage: limit, list, page, total }"
              ></program-all> -->
            </v-tab-item>
            <v-tab-item>
              <program-publish
                :loading="table_loading"
                :data="dataPublish"
                @remove="remove"
                @update="update"
                @refresh-publish="fetchPublish"                
                @sorting="sortingPublish"                
              ></program-publish>
                <!-- :data="{ perPage: limitPublish, listPublish, pagePublish, totalPublish }" -->
            </v-tab-item>
            <v-tab-item>
              <program-draft
                :loading="table_loading"
                :data="dataDraft"
                @remove="remove"
                @update="update"
                @refresh-draft="fetchDraft"                
                @sorting="sortingDraft"                
              ></program-draft>
              <!-- <program-draft
                @remove="remove"
                @update="update"
                :data="{ perPage: limitDraft, listDraft, pageDraft, totalDraft }"
              ></program-draft> -->
            </v-tab-item>
            <v-tab-item>
              <program-archive
                :loading="table_loading"
                :data="dataArchive"
                @remove="remove"
                @update="update"
                @refresh-archive="fetchArchive"                
                @sorting="sortingArchive"                
              ></program-archive>
              <!-- <program-archive
                @remove="remove"
                @update="update"
                :data="{ perPage: limitArchive,listArchive, pageArchive, totalArchive }"
              ></program-archive> -->
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog" max-width="600px" persistent>
        <v-card>
          <v-card-title>
            <span>  Export </span>
          </v-card-title>
          <v-divider></v-divider>
          <div>
            <v-card-text>
              <v-col cols="12">
                <div class="mb-2">Program</div>
                <v-autocomplete
                  v-model="program_"
                  :items="list_program"
                  :item-text="'title'"
                  :item-value="'id'"
                  dense
                  filled
                  multiple
                  chips
                ></v-autocomplete>
              </v-col>
              <v-col id="progres-icon" cols="12">
                <v-row>
                  <v-col cols="12">
                    <div class="mb-2">Tanggal</div>
                    <v-select
                      v-model="sort"
                      :items="sorts"
                      :item-text="'title'"
                      :item-value="'name'"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-show="sort" id="progres-icon" cols="12">
                <v-row>
                  <v-col cols="5">
                    <div class="mb-2">Tanggal Mulai</div>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          append-icon="ri-calendar-line"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="date = null"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        :active-picker.sync="activePicker"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="5">
                    <div class="mb-2">Tanggal Akhir</div>
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date2"
                          append-icon="ri-calendar-line"                          
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="date2 = null"
                          clearable
                          readonly
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date2"
                        :active-picker.sync="activePicker2"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="save2"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2" class="pl-0">
                    <div class="mb-2 white--text">Tanggal</div>                    
                    <v-btn color="primary" elevation="0" @click="resetTgl()">
                      Reset
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>                  
              <v-col v-show="sort === 'created_at'" id="progres-icon" cols="12">
                <v-row>
                  <v-col cols="12">
                    <div class="mb-2">Progress</div>
                    <v-select
                      v-model="progress"
                      :items="progs"
                      :item-text="'name'"
                      :item-value="'id'"
                    ></v-select>
                    <!-- <v-text-field dense v-model.number="progress_start" type="number" append-icon="ri-percent-fill"></v-text-field> -->
                  </v-col>
                  <!-- <v-col cols="6">
                    <div class="mb-2">Progress (Batas Maksimal)</div>
                    <v-text-field dense v-model.number="progress_end" type="number" append-icon="ri-percent-fill"></v-text-field>
                  </v-col> -->
                </v-row>
              </v-col>                          
            </v-card-text>            
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" small elevation="0" @click="dialog=false;sort=''; dialogState=''">
              Batal
            </v-btn>
            <v-btn
              v-if="dialogState === 'epm'"
              class="white--text"
              color="primary"
              small
              elevation="0"
              :loading="submitting"
              @click="ExportEPM"
            >
              Export TPM & UK 
            </v-btn>
            <v-btn
              v-if="dialogState === 'program'"
              class="white--text"
              color="primary"
              small
              elevation="0"
              :loading="submitting"
              @click="download"
            >
              Download
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="is_delete.show" max-width="600px">
        <v-card>
          <v-card-title>
            <span> <v-icon>$warning</v-icon> Hapus Program </span>
          </v-card-title>
          <v-divider></v-divider>
          <div>
            <v-card-text>
              Apakah anda ingin menghapus program <strong class="text-capitalize">{{ this.prog.title }} </strong>ini ?
            </v-card-text>            
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" small elevation="0" @click="cancel()">
              Batal
            </v-btn>
            <v-btn
              class="white--text"
              color="primary"
              small
              elevation="0"
              :loading="submitting"
              @click="hapus(is_delete.id)"
            >
              Ya, Hapus!
            </v-btn>            
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="is_change.show" max-width="600px">
        <v-card>
          <v-card-title>
            <span> <v-icon>$warning</v-icon> Update status </span>
          </v-card-title>
          <v-divider></v-divider>
          <div>
            <v-card-text v-if="prog.status === 'draft' || prog.status === 'archive'">
              Apakah anda ingin mengubah status program dari  {{ this.prog.status }} menjadi Publish  ?
            </v-card-text>
            <v-card-text v-else>
              Apakah anda ingin mengubah status program dari  {{ this.prog.status }} menjadi archive  ?
            </v-card-text>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" small elevation="0" @click="cancelChange()">
              Batal
            </v-btn>
            <v-btn
              v-if="prog.status =='draft' || prog.status === 'archive'"
              class="white--text"
              color="primary"
              small
              elevation="0"
              @click="updatePublish(is_change.id)"
            >
              Ya, Ubah!
            </v-btn>
            <v-btn
              v-else
              class="white--text"
              color="primary"
              small
              elevation="0"
              @click="updateArchive(is_change.id)"
            >
              Ya. Ubah!
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>      

      <v-snackbar 
        top 
        v-model="snackbar.state"
        color="primary"
        outlined
        :timeout="timeout"
      >
        <div v-html="snackbar.text"></div>
        <template v-slot:action="{ attrs }">
          <v-btn
            small
            icon
            color="error"
            v-bind="attrs"
            @click="snackbar.state = false"
          >
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>      
    </v-col>
  </v-row>
</template>

<script>
import ProgramAll from "./components/Table";
import ProgramPublish from "./components/TablePublish";
import ProgramDraft from "./components/TableDraft";
import ProgramArchive from "./components/TableArchive";
import { mapGetters} from 'vuex';
// import downloadExcel from "vue-json-excel";
// import moment from "moment";


export default {
  name: "program-list",
  components: { ProgramAll,ProgramPublish,ProgramDraft, ProgramArchive },
  data: () => ({
    newPaket: "",
    class_type: 0,
    loading:false,
    all: {},
    publishes:{},
    draft:{},
    tabPaket: null,
    submitting:false,
    table_loading:false,
    is_delete: {
      show:false,
      id:''
    },
    snackbar: {
        state: false,
        text: "",
      },
    timeout:5000,
    is_change:{
      show:false,
      id:''
    },
    new_status: "",
    new_items:"",
    prog:{}, 
    name_program:"",
    dir:'',
    dirPublish:'',

    list_program:[],
    program_:[],
    progress_end:null,
    progress_start:null,
    dialog:false,
    dialogState: "",
    activePicker: null,
    date: '',
    menu: false,
    activePicker2: null,
    date2: '',
    menu2: false,
    progress:'',
    sort:'',
    json_fields:{
      'Nama Program':'program.title',
    },
    json_data:{},    
    progs:[
      {
        id:'1',
        name:'All'
      },
      {
        id:'2',
        name:'100%'
      },
    ],
    sorts:[
      {
        title:'Tanggal Redeem',
        name:'created_at'
      },
      {
        title:'Tanggal Penyelesaian',
        name:'end_test_at'
      },
    ],
    url_:''
    // disabledFn: {
    //     customPredictor(date) {
    //       if (date.getDate() % 3 === 0) {
    //         return true;
    //       }
    //     }        
    //   },
  }),
  computed: {
    isPaketEmpty: () => false,
    ...mapGetters({ data: "program/data", params: "program/params" }),    
    ...mapGetters({ dataPublish: "program/dataPublish", paramsPublish: "program/paramsPublish" }),    
    ...mapGetters({ dataDraft: "program/dataDraft", paramsDraft: "program/paramsDraft" }),    
    ...mapGetters({ dataArchive: "program/dataArchive", paramsArchive: "program/paramsArchive" }),    
    // ...mapGetters("program", ["list", "page", "limit", "total"]),P
    // ...mapGetters("program", ["listPublish", "pagePublish", "limitPublish", "totalPublish"]),

    // ...mapGetters("program", ["listDraft", "pageDraft", "limitDraft", "totalDraft"]),
    // ...mapGetters("program", ["listArchive", "pageArchive", "limitArchive", "totalArchive"]),

    // computed_search() {
    //   let q = {};
    //     q = this.name_program;
    //   return q;
    // },
  },
  watch: {
      menu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
      menu2 (val) {
        val && setTimeout(() => (this.activePicker2 = 'YEAR'))
      },
      progress_start(newVal){
      if(newVal === '') {
        this.progress_start = null;
      }
    },
    progress_end(newVal){
      if(newVal === '') {
        this.progress_end = null;
      }
    },
    class_type(value) {
      if(value == 0) {
        this.class_type = ''
      }
      this.fetchAll()
    },
    name_program(value){
      this.name_program = value
      this.fetchAll()
    }
  },
  created() {
    
  },
  mounted() {
    this.$emit("page-changed", 0, { title: "Kelola Program", link: "/program" });

    this.fetchAll();
  },
  methods: {
    listProgram() {
      this.axios
        .get("/program/v1/company/list",{
          params: {
            status: 'publish',
            limit: -1,
            class_type: this.class_type == 0 ? '' : this.class_type
          }
        })
        .then((response) => {
          let res = response.data.data.list;
          this.list_program = res;
        });
    },
    save (date) {
      this.$refs.menu.save(date)
    },
    save2 (date2) {
      this.$refs.menu2.save(date2)
    },

    ExportEPM() {

      this.submitting=true;
      if (this.progress === '1') {
        this.progress_start=0;
        this.progress_end=100;
      }else if (this.progress === '2') {
        this.progress_start=100;
        this.progress_end=100;
      }else{
        this.progress_start=100;
        this.progress_end=100;
      }
      let data = {
        id:this.program_,
      }

      this.axios
        .post(`users/v1/export/member_redeem_epm?sort=${this.sort}&range_date_type=${this.sort}&limit=-1&progress_start=${this.progress_start}&progress_end=${this.progress_end}&start_date=${this.date}&end_date=${this.date2}`, data)
        .then((res) => {
          this.submitting=false;
          this.url_ = res.data.data.public_url;
          this.forceFileDownload(this.url_)
          this.dialog=false;
          this.program_=[];
          this.sort='';
          this.progress_start='';
          this.progress_end='';
          this.start_date=null;
          this.end_date=null;
          this.snackbar.text = 'Successfully export data';
          this.snackbar.state = true;
        })
        .catch((res) => {
          this.submitting=false;
          this.snackbar.text = res.response.data.message;
          this.snackbar.state = true; 
        });

    },
    
    download(){
      // var a = moment(this.date);
      // var b = moment(this.date2);
      // let ab=b.diff(a, 'days');

      // if (ab >= 32 ) {
      //   this.snackbar.state = true;        
      //   this.snackbar.text = 'Periode yang anda pilih lebih dari 31 hari';
      // }
      // else{        
        this.submitting=true;
        if (this.progress === '1') {
          this.progress_start=0;
          this.progress_end=100;
        }else if (this.progress === '2') {
          this.progress_start=100;
          this.progress_end=100;
        }else{
          this.progress_start=100;
          this.progress_end=100;
        }
        let data = {
          id:this.program_,
        }

        this.axios
          .post(`users/v1/export/member_redeem?sort=${this.sort}&range_date_type=${this.sort}&limit=-1&progress_start=${this.progress_start}&progress_end=${this.progress_end}&start_date=${this.date}&end_date=${this.date2}`, data)
          .then((res) => {
            this.submitting=false;
            this.url_ = res.data.data.public_url;
            this.forceFileDownload(this.url_)
            this.dialog=false;
            this.program_=[];
            this.sort='';
            this.progress_start='';
            this.progress_end='';
            this.start_date=null;
            this.end_date=null;
            this.snackbar.text = 'Successfully export data';
            this.snackbar.state = true;
          })
          .catch((res) => {
            this.submitting=false;
            this.snackbar.text = res.response.data.message;
            this.snackbar.state = true; 
          });
      // }

    }, 
    forceFileDownload(url) {
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'file.png') //or any other extension
      document.body.appendChild(link)
      link.click()
    },
    resetTgl(){
      this.date=null;
      this.date2=null;
    },     
    fetchAll(){
      this.fetchProgram();
      this.fetchPublish();
      this.fetchDraft();
      this.fetchArchive();
    },
    fetchProgram() {
      // this.loading = true;
      // this.$store.dispatch("program/list",{q:this.name_program}).finally(() => {
      //   this.loading = false;
      // });
      this.table_loading = true;      
      this.$store.dispatch("program/list",{q: this.name_program, class_type: this.class_type == 0 ? '' : this.class_type}).finally(() => {
        this.table_loading = false;
      });
    },
    sorting(value) {
      if (value === true) {
        this.dir='desc'
      }else{this.dir='asc'}
      this.table_loading = true;    
      this.$store.dispatch("program/list",{q: this.name_program,class_type: this.class_type == 0 ? '' : this.class_type,sort:'total_participants',sort_type:this.dir}).finally(() => {
        this.table_loading = false;
      });         
    },
    fetchPublish() {
      this.loading = true;
      this.$store.dispatch("program/publish",{q:this.name_program,class_type: this.class_type == 0 ? '' : this.class_type}).finally(() => {
        this.loading = false;
      });
    },
    sortingPublish(value) {
      if (value === true) {
        this.dirPublish='desc'
      }else{this.dirPublish='asc'}
      this.table_loading = true;    
      this.$store.dispatch("program/publish",{q: this.name_program,class_type: this.class_type == 0 ? '' : this.class_type,sort:'total_participants',sort_type:this.dirPublish}).finally(() => {
        this.table_loading = false;
      });         
    },
    fetchDraft() {
      this.loading = true;
      this.$store.dispatch("program/draft",{q:this.name_program,class_type: this.class_type == 0 ? '' : this.class_type}).finally(() => {
        this.loading = false;
      });
    },
    sortingDraft(value) {
      if (value === true) {
        this.dir='desc'
      }else{this.dir='asc'}
      this.table_loading = true;    
      this.$store.dispatch("program/draft",{q: this.name_program,class_type: this.class_type == 0 ? '' : this.class_type,sort:'total_participants',sort_type:this.dir}).finally(() => {
        this.table_loading = false;
      });         
    },
    fetchArchive() {
      this.loading = true;
      this.$store.dispatch("program/archive",{q:this.name_program,class_type: this.class_type == 0 ? '' : this.class_type}).finally(() => {
        this.loading = false;
      });
    },
    sortingArchive(value) {
      if (value === true) {
        this.dir='desc'
      }else{this.dir='asc'}
      this.table_loading = true;    
      this.$store.dispatch("program/archive",{q: this.name_program,class_type: this.class_type == 0 ? '' : this.class_type,sort:'total_participants',sort_type:this.dir}).finally(() => {
        this.table_loading = false;
      });         
    },
    update(program) {      
      this.is_change.show=true;
      this.is_change.id=program.id;
      this.prog=program;    
    },
    remove(program) {
      this.is_delete.show=true;
      this.is_delete.id=program.id;
      this.prog=program;      
    },
    edit(){},
    // update(item, status){
    //   this.new_items = item;
    //   this.new_status = status;
    // },
    cancel() {
      this.is_delete.show = false;
    },
    cancelChange() {
      this.is_change.show = false;
    },
    hapus(id){
      this.is_delete.id=id;
      // console.log('id delete',this.is_delete.id);
      this.submitting=true;
      this.axios
        .delete(`/program/v1/company/delete/${this.is_delete.id}`)
        .then((res) =>{
          this.submitting=false;
          this.is_delete.show=false;
          if (res.status == 200) {
            this.snackbar.text = `Program berhasil dihapus`;
            this.snackbar.state = true;
            this.fetchAll();

          }else{
            this.snackbar.text = res.message;
            this.snackbar.state = true;
          } 
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updatePublish(id){
      this.is_change.id=id;
      this.submitting=true;
      const data ={
        status:'publish'
      }
      this.axios
        .put(`/program/v1/company/update-status/${this.is_change.id}`, data)
        .then((res) =>{
          this.submitting=false;
          this.is_delete.show=false;
          if (res.status == 200) {
            this.snackbar.text = `Status program berhasil diubah`;
            this.snackbar.state = true;
            this.is_change.show=false;            
            this.fetchAll();
          }else{
            this.snackbar.text = res.message;
            this.snackbar.state = true;
          } 
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateArchive(id){
      this.is_change.id=id;
      this.submitting=true;
      const data ={
        status:'archive'
      }
      this.axios
        .put(`/program/v1/company/update-status/${this.is_change.id}`, data)
        .then((res) =>{
          this.submitting=false;
          this.is_delete.show=false;
          if (res.status == 200) {
            this.snackbar.text = `Status program berhasil diubah`;
            this.snackbar.state = true;
            this.fetchAll();
            this.is_change.show=false;

          }else{
            this.snackbar.text = res.message;
            this.snackbar.state = true;
          } 
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },
};
</script>
<style type="text/css">
  .v-select.v-input--dense .v-chip {
    margin:8px 4px 10px 4px;
}
</style>