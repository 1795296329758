<template>
  <v-dialog v-model="data" max-width="800" persistent>
    <v-card>
      <v-card-title class="headline">Export Data</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <label for="" class="font-weight-medium">Pilih Tanggal Mulai</label>
            <v-menu
              v-model="startDate"
              :close-on-content-click="false"
              max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  filled
                  outlined
                  class="mt-2"
                  color="primary"
                  :value="computedStartDateFormatted"
                  clearable
                  placeholder="Silahkan pilih tanggal mulai"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="filter.start_date = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filter.start_date"
                :max="date_now"
                @change="startDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <label for="" class="font-weight-medium">Pilih Waktu Mulai</label>
            <v-text-field
              filled
              outlined
              class="mt-2"
              color="primary"
              v-model="filter.start_time"
              placeholder="silahkan pilih waktu mulai"
              type="time"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <label for="" class="font-weight-medium">Pilih Tanggal Selesai</label>
            <v-menu
              v-model="endDate"
              :close-on-content-click="false"
              max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  filled
                  outlined
                  class="mt-2"
                  color="primary"
                  :value="computedEndDateFormatted"
                  clearable
                  placeholder="Silahkan pilih tanggal selesai"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="filter.end_d = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filter.end_date"
                :max="date_now"
                @change="endDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <label for="" class="font-weight-medium">Pilih Waktu Selesai</label>
            <v-text-field
              filled
              outlined
              class="mt-2"
              color="primary"
              v-model="filter.end_time"
              placeholder="Silahkan pilih waktu selesai"
              type="time"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" :disabled="loadingExport" @click="close">Cancel</v-btn>
        <v-btn color="primary" :loading="loadingExport" @click="exportData">Export</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import model from '@/views/redeem-log/components/_mixins/model'
import moment from 'moment'
export default {
  mixins: [model],
  props: {
    filter: {
      type: Object,
      default: () => ({
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
      }),
    },
    loadingExport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date_now: moment(new Date()).format('YYYY-MM-DD'),
      startDate: false,
      endDate: false,
    }
  },
  computed: {
    computedStartDateFormatted() {
      return this.filter.start_date ? moment(this.filter.start_date).format('DD MMMM YYYY') : ''
    },
    computedEndDateFormatted() {
      return this.filter.end_date ? moment(this.filter.end_date).format('DD MMMM YYYY') : ''
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    exportData() {
      this.dialog = false
      this.$emit('export', this.filter)
    },
  }
}
</script>