<template>
  <v-row>
    <v-col cols="12">
      <div class="font-weight-bold">{{ settingTitle }}</div>
    </v-col>
    <v-col cols="12">
      <div class="font-weight-medium">
        <span>  {{ settingLabel }} </span>
      </div>
      <v-row no-gutters>
        <v-col cols="12" md="7">
          <v-row>
            <v-col>
              <v-text-field v-model="data.estimated_duration" type="number" min="0" placeholder="0"></v-text-field>
            </v-col>
            <v-col cols="12" class="mt-3" v-if="settingType == 'video'">
              <div class="font-weight-medium">
                <span>Gunakan Percepatan Video</span>
              </div>
              <v-radio-group v-model="data.is_show_video_control" class="mt-0">
                <v-radio :value="1">
                  <span slot="label" class="color--text">Ya</span>
                </v-radio>
                <v-radio :value="0">
                  <span slot="label" class="color--text">Tidak</span>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <!-- <v-col cols="12">
      <v-alert type="warning" text outlined>
        Wajib untuk klik simpan setelah mengisi data pengaturan
      </v-alert>
    </v-col>

    <v-col cols="12">
      <div class="font-weight-bold">Pengaturan Pop Up Quiz</div>
    </v-col>

    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Tampilkan Pembahasan </span>
      </div>
      <v-radio-group>
        <v-radio :value="1">
          <span slot="label" class="color--text">Ya</span>
        </v-radio>
        <v-radio :value="0">
          <span slot="label" class="color--text">Tidak</span>
        </v-radio>
      </v-radio-group>
    </v-col>

    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Tampilkan Koreksi </span>
      </div>
      <v-radio-group>
        <v-radio :value="1">
          <span slot="label" class="color--text">Ya</span>
        </v-radio>
        <v-radio :value="0">
          <span slot="label" class="color--text">Tidak</span>
        </v-radio>
      </v-radio-group>
    </v-col>

    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Atur Nilai Minimal </span>
      </div>
      <v-radio-group v-model="isMinimal">
        <v-radio :value="1">
          <span slot="label" class="color--text">Ya</span>
        </v-radio>
        <v-radio :value="0">
          <span slot="label" class="color--text">Tidak</span>
        </v-radio>
      </v-radio-group>
    </v-col>

    <v-col v-if="isMinimal == 1" cols="12">
      <div class="font-weight-medium mb-2">
        <span> Nilai Minimal </span>
      </div>
      <v-text-field placeholder="Masukkan Nilai Minimal"></v-text-field>
    </v-col>

    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Kesempatan Mengulang </span>
      </div>
      <v-select :items="retryOptions" placeholder="Pilih kesempatan mengulang"></v-select>
    </v-col>

    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Tampilkan Timer </span>
      </div>
      <v-radio-group>
        <v-radio :value="1">
          <span slot="label" class="color--text">Ya</span>
        </v-radio>
        <v-radio :value="0">
          <span slot="label" class="color--text">Tidak</span>
        </v-radio>
      </v-radio-group>
    </v-col>

    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Tampilkan Timer </span>
      </div>
      <v-radio-group>
        <v-radio :value="1">
          <span slot="label" class="color--text">Ya</span>
        </v-radio>
        <v-radio :value="0">
          <span slot="label" class="color--text">Tidak</span>
        </v-radio>
      </v-radio-group>
    </v-col>

    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Tampilkan Score </span>
      </div>
      <v-radio-group>
        <v-radio :value="1">
          <span slot="label" class="color--text">Ya</span>
        </v-radio>
        <v-radio :value="0">
          <span slot="label" class="color--text">Tidak</span>
        </v-radio>
      </v-radio-group>
    </v-col>

    <v-col cols="12">
      <v-checkbox>
        <span slot="label" class="color--text">Acak urutan soal / grup soal</span>
      </v-checkbox>
    </v-col>

    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Jumlah Soal per Paket</span>
      </div>
      <v-text-field placeholder="Masukkan jumlah soal per paket"></v-text-field>
    </v-col>

    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Gunakan Timer </span>
      </div>
      <v-radio-group>
        <v-radio :value="1">
          <span slot="label" class="color--text">Ya</span>
        </v-radio>
        <v-radio :value="0">
          <span slot="label" class="color--text">Tidak</span>
        </v-radio>
      </v-radio-group>
    </v-col>

    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Estimasi Durasi (Menit)</span>
      </div>
      <v-text-field placeholder="Masukkan estimasi durasi (menit)"></v-text-field>
    </v-col>

    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Waktu Ujian (Menit)</span>
      </div>
      <v-text-field placeholder="Masukkan waktu ujian (menit)"></v-text-field>
    </v-col> -->
  </v-row>
</template>
<script>
import model from "../_mixins/model";
export default {
  mixins: [model],
  props: {
    settingTitle: String,
    settingType: String,
    settingLabel: String,
  },
  data: () => ({
    isMinimal: null,
  }),
  computed: {
    retryOptions() {
      return [
        { value: 1, text: "1 x" },
        { value: 10, text: "10 x" },
        { value: 50, text: "50 x" },
        { value: 100, text: "100 x" },
        { value: 0, text: "Tanpa mengulang" },
        { value: -1, text: "Mengulang tanpa batas" },
      ];
    },
  },
};
</script>
