import axios from "axios";
import url from "./api";

const config = { headers: { "content-type": "application/json" } };

const state = () => ({
  data: {},
});

const getters = {
  list: (state) => state.data.list || [],
  limit: (state) => state.data.limit || 5,
  page: (state) => state.data.page || 1,
  total: (state) => state.data.total || "0",
  detail: (state) => state.data.detail || {},
};

const mutations = {
  SET_DATA: (state, data) => {
    state.data = { ...state.data, ...data };
  },
  SET_DETAIL: (state, data) => {
    state.data = { ...state.data, ...{ detail: data } };
  },
};

const actions = {
  async list({ commit }, params) {
    params = { ...{ sort: 'created_at', sort_type: 'asc', limit:-1 }, ...params };
    return axios
      .get(url.studi_list, { params })
      .then((res) => res.data)
      .then((res) => {
        // add data to state
        let data = {
          id: "0",
          title: "+ Lainnya",
        };
        res.data.list.push(data);

        commit("SET_DATA", res.data);
      });
  },
  async detail({ commit }, id) {
    return axios
      .get(url.studi_detail.replace("{id}", id))
      .then((res) => res.data)
      .then((res) => {
        commit("SET_DETAIL", res.data);
      });
  },
  async create({ dispatch }, data) {
    return axios.post(url.studi_create, data).then(dispatch("list"));
  },
  //belum fix struktur data
  async update({ dispatch }, data) {
    data = {
      id: data.id,
      category_id: data.category_id || data.category.id,
      name: data.name,
      available_part: data.available_part,
    };

    return axios
      .post(url.studi_update.replace("{id}", data.id), data, config)
      .then(dispatch("list"));
  },
  async delete({ dispatch }, id) {
    return axios
      .delete(url.studi_delete.replace("{id}", id))
      .then(dispatch("list"));
  },
};

export default { namespaced: true, state, getters, mutations, actions };
